import React, { CSSProperties } from 'react';
import { Tag } from 'src/components';
import { useTheme } from '@superset-ui/core';

export type OnClickHandler = React.MouseEventHandler<HTMLElement>;

export type Type =
  | 'alert'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'default'
  | 'primary'
  | 'secondary';

export interface LabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  key?: string;
  className?: string;
  onClick?: OnClickHandler;
  type?: Type;
  status?: 'published' | 'draft';
  style?: CSSProperties;
  children?: React.ReactNode;
  role?: string;
}

export default function Label(props: LabelProps) {
  console.log('Label props:', props);
  const theme = useTheme();
  const { colors, transitionTiming } = theme;
  const {
    type = 'default',
    status,
    onClick,
    children,
    ...rest
  } = props;
  const {
    alert,
    primary,
    secondary,
    grayscale,
    success,
    warning,
    error,
    info,
  } = colors;

  console.log("Type:", type); 
  console.log("Status:", status);

  let backgroundColor = grayscale.light3;
  let backgroundColorHover = onClick ? primary.light2 : grayscale.light3;
  let borderColor = onClick ? grayscale.light2 : 'transparent';
  let borderColorHover = onClick ? primary.light1 : 'transparent';
  let color = grayscale.dark1;

  if (type !== 'default') {
    color = grayscale.light4;

    let baseColor;
    if (type === 'alert') {
      color = grayscale.dark1;
      baseColor = alert;
    } else if (type === 'success') {
      baseColor = success;
    } else if (type === 'warning') {
      baseColor = warning;
    } else if (type === 'danger') {
      baseColor = error;
    } else if (type === 'info') {
      baseColor = info;
    } else if (type === 'secondary') {
      baseColor = secondary;
    } else {
      baseColor = primary;
    }

    backgroundColor = baseColor.base;
    backgroundColorHover = onClick ? baseColor.dark1 : baseColor.base;
    borderColor = onClick ? baseColor.dark1 : 'transparent';
    borderColorHover = onClick ? baseColor.dark2 : 'transparent';
  }

  if (status === 'published') {
    backgroundColor = '#e2ffef'; 
    borderColor = '#26bc6a'; 
    color = '#53bb82';
  } else if (status === 'draft') {
    backgroundColor = '#f5e2c5'; 
    borderColor = '#ffbb33'; 
    color = '#ea964c';
  } else {
    backgroundColor = grayscale.light3;
  }
  

  console.log("Background color:", backgroundColor);

  return (
    <Tag
      onClick={onClick}
      {...rest}
      css={{
        transition: `background-color ${transitionTiming}s`,
        whiteSpace: 'nowrap',
        cursor: onClick ? 'pointer' : 'default',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        backgroundColor,
        borderColor,
        borderRadius: 5,
        padding: '0.35em 0.8em',
        lineHeight: 1,
        color,
        maxWidth: '100%',
        // '&:hover': {
        //   backgroundColor: backgroundColorHover,
        //   borderColor: borderColorHover,
        //   opacity: 1,
        // },
      }}
    >
      {children}
    </Tag>
  );
}
